//
// Header
//

header {
  position: fixed;
  width: 100%;
  top: -1px;
  z-index: 9999;
  background: $white;
  height: 90px;
  transform: translateZ(0);
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);

  .logo img {
    width: 160px;
  }

  .flex {
    align-items: center;
  }

  & + div {
    padding-top: 90px;
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $darkgrey;
  opacity: 1 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $darkgrey;
  opacity: 1 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: $darkgrey;
  opacity: 1 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $darkgrey;
  opacity: 1 !important;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
  .lgwrap {
    top: 20px;
    right: 80px;
  }

  header .logo {
    width: 23.584%;
    float: left;
  }

  header {
    height: 80px;
  }

  header + div {
    padding-top: 80px;
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {
  .lgwrap {
    top: 10px;
  }

  header .logo img {
    width: 105px;
  }
}
@media all and (max-width:$size-S2) {
  header .logo {
    width: 35%;
  }

  header {
    height: 70px;
  }

  header + div {
    padding-top: 70px !important;
  }
}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
