//
// Project Color Definitions
//
$white: #ffffff;
$black: #1c1c1c;
$darkgrey: #3D3D3D;
$middlegrey: #696969;
$lightgrey: #A6A6A6;
$whitegrey: #F5F5F5;
$neured: #98020E;
$neumidred: #AC3434;
$neulightred: #B96F6F;
