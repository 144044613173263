/* Bild */
.bild50x2 {
  img {
    width: 100%;
  }
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
  .imagebox .g-4:not(:last-child) {
    padding-bottom: 20px;
  }

  .coverfit.g-20 {
    width: 33.333%;
  }

  .coverfit.g-20:nth-last-child(-n+2) {
    display: none;
  }
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
