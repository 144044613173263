/*
Theme Name: ModeNeu2021
Theme URI: https://www.mode-neu.de
Description: Mode Neu Moosburg
Version: 1.0
Author: shmyzu
Author URI: http://www.shmyzu.de
*/

// Normalize
@import "base/normalize";
// @import "base/webfonts";

// Mixins
@import "base/mixins";

// Base Settings
@import "base/settings";
@import "base/colors";
@import "base/webfonts";
@import "base/headings";
@import "base/grid";
@import "base/spacings";
@import "base/buttons";

// Global CSS
@import "base/global";

// Basic Layout
@import "header";
@import "nav-main";
@import "main";
@import "footer";

// Libraries
@import "lib/slick";
@import "lib/mmenu";

// Modules
@import "modules/bildtext";
@import "modules/bild";
@import "modules/text";
@import "modules/dhweiche";
@import "modules/slideshow";
@import "modules/news";
@import "modules/service";
@import "modules/kontakt";
@import "modules/video";
