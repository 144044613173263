//
// Base Grid
//

.container {
  margin: 0 auto;
  max-width: 1060px;
  width: 100%;
  position: relative;
}

.container.centered p {
  margin: 0 auto;
  text-align: center;
}

.container .centered {
  margin: 0 auto;
  text-align: center;
}

.container-fullwidth::after {
  clear: both;
  content: " ";
  display: table;
}

.container_fullwidth {
  width: 100%;
  position: relative;
}

.container .col {
  display: block;
  float: left;
  box-sizing: border-box;
}

.container .col:first-child {
  margin: 0;
}

.ontop {
  z-index: 100;
  position: relative;
}

.flex {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-flow: row wrap;

  article img {
    width: 100%;
  }

  .mb-10,
  .mb-10:first-child {
    margin-bottom: 0.8vw;
  }
}

.flexcol {
  display: flex;
  flex-direction: column;
}

.flex-start {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.flex-start .g-4 {
  box-sizing: content-box;
}

.flex-end {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.flex-center {
  justify-content: center;
}

.container.flex-row {
  flex-direction: row;
}

.fourth .g-3 {
  margin-bottom: 20px;
}

.fourth::after {
  content: '';
  display: block;
  width: 49.056%;
}

.g-1 {
  width: 6.603%;
}

.g-2 {
  width: 15.094%;
}

.g-20 {
  width: 20%;
}

.g-3 {
  width: 23.584%;
}

.g-4 {
  width: 32.075%;
}

.g-5 {
  width: 40.566%;
}

.g-6 {
  width: 49.056%;
}

.g-7 {
  width: 57.547%;
}

.g-8 {
  width: 66.037%;
}

.g-9 {
  width: 74.528%;
}

.g-10 {
  width: 83.018%;
}

.g-11 {
  width: 91.509%;
}

.g-12 {
  width: 100%;
}

.pl-1 {
  padding-left: 1.887%;
}

.pl-2 {
  padding-left: 16.981%;
}

.pl-3 {
  padding-left: 25.471%;
}

.pl-4 {
  padding-left: 33.962%;
}

.pl-5 {
  padding-left: 42.453%;
}

.pl-6 {
  padding-left: 50.943%;
}

.pl-7 {
  padding-left: 59.434%;
}

.pl-8 {
  padding-left: 67.924%;
}

.pl-9 {
  padding-left: 76.415%;
}

.pl-10 {
  padding-left: 84.905%;
}

.pl-11 {
  padding-left: 93.396%;
}

.pr-1 {
  padding-right: 1.887%;
}

.pr-2 {
  padding-right: 8.49%;
}

.pr-3 {
  padding-right: 25.471%;
}

.pr-4 {
  padding-right: 33.962%;
}

.pr-5 {
  padding-right: 42.453%;
}

.pr-6 {
  padding-right: 50.943%;
}

.pr-7 {
  padding-right: 59.434%;
}

.pr-8 {
  padding-right: 67.924%;
}

.pr-9 {
  padding-right: 76.415%;
}

.pr-10 {
  padding-right: 84.905%;
}

.pr-11 {
  padding-right: 93.396%;
}
@media all and (min-width:$size-XXL) {
  .container {
    max-width: 1440px;
  }
}
@media all and (max-width:$size-XXL) {
  .container {
    max-width: 1280px;
  }
}
@media all and (max-width:$size-XL2) {
  .container {
    max-width: 1024px;
  }

}
@media all and (max-width:$size-XL) {
  .container {
    padding: 0 40px;
  }

  .g-8,
  .g-9 {
    width: 100%;
  }
}
@media all and (max-width:$size-L) {
  main > div.container-fullwidth:nth-child(3) {
    padding-top: 50px;
  }
}
@media all and (max-width:$size-M2) {
  main > div:first-child {
    padding-top: 60px;
  }

  main > div:last-child {
    padding-top: 60px;
  }

  .flex .g-3 {
    width: 32.075%;
  }

  .flex.fourth::after {
    width: 32.075%;
  }

  .g-4.pr-2 {
    padding-right: 0;
  }

  .flex-start {
    justify-content: space-between;
  }
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {
  .container {
    padding: 0 30px;
  }
}
@media all and (max-width:$size-S) {
  .g-2 {
    width: 32.075%;
  }

  .g-10,
  .g-2.lightredbg,
  .g-4,
  .g-5,
  .g-6,
  .g-7 {
    width: 100%;
  }

  .g-2.lightredbg {
    padding: 20px 0;
  }

  .fourth .g-3 {
    width: 49.056%;
  }

  main > div:first-child {
    padding-top: 40px;
  }

  main > div:last-child {
    padding-top: 40px;
  }
}
@media all and (max-width:$size-XS2) {
  .fourth .g-3 {
    width: 100%;
  }
}
@media all and (max-width:$size-XS) {}
