//
// Main CSS
//

::selection {
  color: $white;
  background: $neured;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: $neured;
}

::-webkit-scrollbar-track {
  background: $whitegrey;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $neumidred;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
