/* Text Module */
.infobubble {
  position: relative;
  display: inline-block;
  width: 6rem;
  height: 6rem;
  background: $neured;
  border-radius: 50%;
  margin-right: 1.5rem;
  transition: transform 0.25s ease-in-out;

  span {
    @include font-size($fontsize-L);
    line-height: 1.4;
  }
}

.historie {
  justify-content: flex-start;

  .g-4 {
    margin-bottom: 40px;
    display: inline-block;
    padding-right: 20px;
  }

  .infobubble {
    float: left;
  }

  span {
    float: left;
    color: $white;
    @include font-size($fontsize-S);
  }

  p {
    margin-top: 10px;
    width: calc(100% - 75px);
    float: left;
  }
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
  .historie .g-4 {
    width: 49.056%;
  }
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
  .marken .g-3 {
    width: 49.056%;
  }

  header + .textwrap {
    padding-top: 100px !important;
  }

  .historie .g-4 {
    width: 100%;
    padding-right: 0;
  }
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
