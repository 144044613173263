/* Damen Herren Weiche */
.dhweiche,
.vertxt figure {
  position: relative;

  .image-wrapper {
    overflow: hidden;
    float: right;
    width: 85%;
    box-shadow: 0 5px 20px rgba(0,0,0,0.2);
  }

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  figcaption {
    float: left;
    transform: rotate(-90deg);
    height: 100%;
    @include font-size($fontsize-XXL);
    color: $neured;
    font-weight: 700;
    text-transform: uppercase;
    transform-origin: left top;
    position: relative;
    right: -40px;
    top: 7px;
  }

  img {
    float: left;
    width: 100%;
    object-fit: cover;
    transition: transform 0.35s ease-in-out;
  }
}

.dhweiche figure:hover {
  img {
    transform: scale(1.10);
  }
}

.vertxt figure {
  display: inline-block;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL3) {
  .dhweiche {
    figcaption {
      right: -20px;
    }
  }
}
@media all and (max-width:$size-XL) {
  .dhweiche {
    .image-wrapper {
      width: 75%;
    }
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
  .dhweiche {
    .image-wrapper {
      width: 70%;
    }

    figcaption {
      @include font-size($fontsize-XL60);
    }
  }
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {
  .dhweiche {
    .image-wrapper {
      width: 65%;
    }

    figcaption {
      @include font-size($fontsize-XL);
    }
  }
}
@media all and (max-width:$size-S) {
  .dhweiche {
    .g-6 {
      width: 49.056%;
    }

    .image-wrapper {
      width: 80%;
    }

    figcaption {
      @include font-size($fontsize-L);
      right: -3px;
      top: 2px;
    }
  }
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
