/* Bildtext */
.bildtext {
  img {
    width: 100%;
  }

  .textbox {
    position: relative;
  }

  .icontext {
    img {
      max-height: 70px;
    }

    h3 {
      margin-bottom: 5px;
    }
  }
}

.vertxt {
  img {
    min-height: 55vh;
  }
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
  .bildtext {
    .vertxt {
      .image-wrapper {
        width: 100%;
        float: left;
      }

      figcaption {
        display: none;
      }
    }
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
  .bildtext {
    .imagebox {
      margin-bottom: 30px !important;
      order: 1;
    }

    .textbox {
      padding-left: 0;
      order: 2;
    }
  }
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
