//
// Footer
//

footer {
  ul {
    @include ul-reset;
  }

  li {
    @include font-size($fontsize-S);
  }

  a,
  p {
    color: $darkgrey;
    @include font-size($fontsize-S);
  }

  h5 {
    color: $white;
    font-weight: 300;
    line-height: 1.2;
    @include font-size($fontsize-L);
  }

  span {
    font-style: italic;
    font-weight: 600;
  }

  .g-3 {
    position: relative;

    img.mapicon {
      position: absolute;
      left: -45px;
      top: -5px;
    }
  }

  .redbg {
    height: 60px;

    a,
    p {
      color: $white;
    }
  }
}

.footer-newsletter {
  padding-bottom: 25px;
  border-bottom: 1px solid $white;
}

.footer-nav > ul > li {
  font-weight: 400;
  float: left;
  margin-right: 100px;
}

.footer-nav > ul > li > ul {
  margin-top: 25px;
}

.footer-nav > ul > li:last-child {
  margin-right: 0;
}

.footer-nav ul ul > li {
  display: block;
  float: none;
  font-weight: 300;
  margin-bottom: 5px;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {
  footer {
    &.pd-50 {
      padding: 30px;
    }

    .flex .g-2,
    .flex .g-3,
    .flex .g-4 {
      width: 49.056%;
    }
  }
}
@media all and (max-width:$size-M2) {
  .footer-newsletter .g-6 {
    width: 32.075%;
  }
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
  footer {
    .pt50-pb100 > div:nth-child(-n + 2) {
      margin-bottom: 40px;
    }

    .mr-40 {
      margin-bottom: 20px;
    }

    .footer-logo {
      max-width: 60%;
    }
  }

  footer .redbg {
    .flex .g-3:first-child {
      width: 40%;
    }

    .flex .g-3:last-child {
      width: 60%;
    }

    .container {
      padding: 0 30px;
    }

    a,
    p {
      @include font-size($fontsize-XS);
    }
  }
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
