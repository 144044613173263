//
// Defaul Project Settings
//
// base inner page content width
$maxPageWidth: 2560px; // base font
$baseFont: 'Open Sans', sans-serif; // font sizes
$fontsize-XXL: 85;
$fontsize-XL70: 70;
$fontsize-XL60: 60;
$fontsize-XL: 45;
$fontsize-L2: 35;
$fontsize-L: 24;
$fontsize-M: 20;
$fontsize-MS: 18;
$fontsize-S: 16;
$fontsize-XS: 14;
//breakpoints
$size-XXL2: 1920px;
$size-XXL: 1680px;
$size-XL3: 1366px;
$size-XL2: 1280px;
$size-XL: 1024px;
$size-L2: 960px;
$size-L: 850px;
$size-M2: 768px;
$size-M: 736px;
$size-S2: 600px;
$size-S: 480px;
$size-XS2: 380px;
$size-XS: 320px;
