//
// Buttons
//

a.linkbtn {
	@include font-size($fontsize-L);
	position: relative;
	font-weight: 700;

	.linkbubble {
		display: inline-block;
		width: 6rem;
		height: 6rem;
		background: $neured;
		border-radius: 50%;
		margin-right: 1.5rem;
		transition: transform 0.25s ease-in-out;
		text-align: center;

		&:before {
			content: '';
			display: inline-block;
			margin-top: 2.25rem;
			margin-left: -0.6rem;
			width: 1.5rem;
			height: 1.5rem;
			border-top: 3px solid $white;
			border-right: 3px solid $white;
			transform: rotate(45deg);
		}
	}
}

a.linkbtn:hover {
	color: $neumidred;

	.linkbubble {
		transform: scale(1.10);
	}
}

.textlink {
	text-transform: uppercase;
	font-weight: 700;
	color: $darkgrey;
	position: relative;
	transition: background 0.35s ease-in-out;
}

.textlink:hover {
	background: $neured;
}

.ybg .textlink:hover {
	background: $darkgrey;
	color: $neumidred;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
	a.linkbtn {
		@include font-size($fontsize-M);
	}
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {
	a.linkbtn {
		@include font-size($fontsize-MS);
	}
}
@media all and (max-width:$size-S) {
	.linkbtn.mt-30 {
		margin-top: 0;
	}

	a.linkbtn {
		.linkbubble {
			width: 5rem;
			height: 5rem;

			&:before {
				margin-top: 1.80rem;
				margin-left: -0.4rem;
			}
		}
	}
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
