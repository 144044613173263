//
// Spacings
//

.pd-25 {
	padding: 25px;
}

.pd-40 {
	padding: 40px;
}

.pd-50 {
	padding: 50px;
}

.pd-100 {
	padding: 100px;
}

.pt-60 {
	padding-top: 60px;
}

.pt-100 {
	padding-top: 100px;
}

.ptb-10 {
	padding: 10px 0;
}

.ptb-20 {
	padding: 20px 0;
}

.ptb-25 {
	padding: 25px 0;
}

.ptb-30 {
	padding: 30px 0;
}

.ptb-50 {
	padding: 50px 0;
}

.ptb-100 {
	padding: 100px 0;
}

.ptb-150 {
	padding: 150px 0;
}

.ptb-200 {
	padding: 200px 0;
}

.pt50-pb100 {
	padding: 50px 0 100px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-80 {
	padding-bottom: 80px;
}

.pb-100 {
	padding-bottom: 100px;
}

.pb-150 {
	padding-bottom: 150px;
}

.ptb-60 {
	padding: 60px 0;
}

.ptb-100 {
	padding: 100px 0;
}

.pl-30 {
	padding-left: 30px;
}

.pl-60 {
	padding-left: 60px;
}

.pr-30 {
	padding-right: 30px;
}
/* Margin Directions */
.mt-10 {
	margin-top: 10px;
}

.mt-25 {
	margin-top: 25px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-50 {
	margin-top: 50px;
}

.mtm-50 {
	margin-top: -50px;
}

.mt-60 {
	margin-top: 60px;
}

.mt-80 {
	margin-top: 80px;
}

.mt-100 {
	margin-top: 100px;
}

.mr-10 {
	margin-right: 10px;
}

.mb-10 {
	margin-bottom: 0.5%;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mb-100 {
	margin-bottom: 150px;
}

.mb-150 {
	margin-bottom: 150px;
}

.mr-10 {
	margin-right: 10px;
}

.mr-20 {
	margin-right: 20px;
}

.mr-40 {
	margin-right: 40px;
}

.wbg + .wbg .imagebox.ptb-150,
.wbg.ptb-150 + .wbg .imagebox.ptb-150,
.wbg.ptb-150 + .wbg.ptb-150 {
	padding-top: 0;
}

.wbg.ptb-150 + .kontaktform {
	margin-top: -75px;
}

.absx,
.absxy,
.absxy .absxy,
.absy {
	position: absolute;
}

.absxy {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.absx {
	left: 50%;
	transform: translateX(-50%);
}

.absy {
	top: 50%;
	transform: translateY(-50%);
}
@media all and (min-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
	.absx,
	.absxy,
	.absy {
		position: relative;
	}
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
	.ptb-50 {
		padding: 30px 0;
	}

	.ptb-150 {
		padding: 100px 0;
	}

	.ptb-100 {
		padding: 75px 0;
	}

	.pt-100 {
		padding-top: 75px;
	}
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {
	.ptb-150 {
		padding: 75px 0;
	}

	.ptb-100 {
		padding: 50px 0;
	}

	.pt-100 {
		padding-top: 50px;
	}
}
@media all and (max-width:$size-S) {
	.ptb-150 {
		padding: 50px 0;
	}

	.pt50-pb100 {
		padding: 40px 0;
	}

	.wbg.ptb-150 + .kontaktform {
		margin-top: 0;
	}

	.pd-40 {
		padding: 30px;
	}

	.ptb-100 {
		padding: 40px 0;
	}

	.pt-100 {
		padding-top: 40px;
	}
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
