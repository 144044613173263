/* News */
.news-section {
  hyphens: auto;

  .date {
    color: $lightgrey;
    @include font-size($fontsize-M);
  }

  .g-6 a,
  .news-archiv a {
    text-transform: uppercase;
    color: $darkgrey;
    font-weight: 700;
  }

  .news-featured {
    h3 {
      @include font-size($fontsize-XL);
      line-height: 6rem;
    }
  }

  .bigoutline::before {
    content: "";
    display: inline-block;
    height: 1px;
    background: $whitegrey;
    position: absolute;
    width: 2000px;
    left: -2030px;
    position: absolute;
    top: 20px;
  }

  .flex {
    align-items: flex-start;
  }
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {
  .news-section {
    article:not(last-child) {
      margin-bottom: 50px;
    }

    img {
      max-height: 420px;
    }

    .g-6 h3 {
      @include font-size($fontsize-L);
      line-height: 3.5rem;
      margin-top: 30px;
    }

    .text-align-right {
      text-align: left;
      margin-bottom: 10px;
    }
  }
}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
