/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/open-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/open-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/open-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/open-sans-v18-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/open-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/open-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/open-sans-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/open-sans-v18-latin-600.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/open-sans-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/open-sans-v18-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/open-sans-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/open-sans-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/open-sans-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/open-sans-v18-latin-700.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/open-sans-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/open-sans-v18-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
