/* Slideshow */
.slideshow {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    float: left;
  }

  .g-4 {
    background: $neured;
    width: 33.333%;
    padding: 70px;
  }

  .g-8 {
    width: 66.667%;
  }

  span {
    font-size: 70px;
    line-height: 75px;
    color: $darkgrey;
    font-weight: 800;
  }

  a {
    text-transform: uppercase;
    color: $darkgrey;
    font-weight: 800;
  }
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {
  .slideshow {
    .g-4,
    .g-8 {
      width: 100%;
    }

    .g-4 {
      padding: 30px;
    }

    span {
      @include font-size($fontsize-L);
      line-height: 45px;
    }
  }
}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
