/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:after,
  &:before {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
    width: 100%;
  }

  &.slick-loading img {
    display: none;
  }
  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}
/* Arrows */
.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  z-index: 300;

  &:before {
    content: '';
    display: inline-block;
    margin-top: 0.25rem;
    margin-left: -0.6rem;
    width: 1.5rem;
    height: 1.5rem;
    border-top: 3px solid $white;
    border-right: 3px solid $white;
    transform: rotate(45deg);
  }
}

.slick-prev {
  background: $neured;
  right: 50px;

  &:before {
    margin-left: 0;
    margin-right: -0.6rem;
    transform: rotate(-135deg);
  }
}

.slick-next {
  background: $neumidred;
}

.slick-slider-hero .slick-slide img {
  max-height: 945px;
  object-fit: cover;
  object-position: top;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {
  .slick-slider-hero {
    .slick-next,
    .slick-prev {
      top: 0;
    }
  }
}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
  .slick-slide img {
    min-height: 240px;
    object-fit: cover;
  }

  .slick-next,
  .slick-prev {
    width: 4rem;
    height: 4rem;
  }

  .slick-prev {
    right: 40px;
  }
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
