//
// Headings
//

h1,
h2 {
  margin: 0 0 30px;
  color: $black;
  @include font-size($fontsize-XXL);
  font-style: normal;
  font-weight: 700;
  line-height: 8rem;
  letter-spacing: -0.1rem;
  text-transform: uppercase;
}

h1 + h2,
h2 + h3 {
  @include font-size($fontsize-XL);
  line-height: 1;
  color: $neured;
  hyphens: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 4.5rem;
  text-transform: none;
}

h2,
h3,
h5 {
  margin: 0 0 20px;
  letter-spacing: -0.04rem;
}

h3 {
  color: $darkgrey;
  font-weight: 700;
  @include font-size($fontsize-L);
  line-height: 4rem;
  margin-bottom: 30px;
}

h4 {
  color: $darkgrey;
  font-weight: 400;
  @include font-size($fontsize-S);
  line-height: 2rem;
  margin-bottom: 15px;
}

h5 {
  color: $darkgrey;
  font-weight: 400;
  @include font-size($fontsize-S);
  line-height: 1.8rem;
  margin-bottom: 10px;
}

h6 {
  color: $darkgrey;
  font-weight: 400;
  @include font-size($fontsize-S);
  line-height: 1.6rem;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.bigoutline {
  @include font-size($fontsize-XXL);
  text-transform: none;
  color: $white;
  text-shadow: -1px 1px 0 #D0D0D0, 1px 1px 0 #D0D0D0, 1px -1px 0 #D0D0D0, -1px -1px 0 #D0D0D0;
  font-weight: 800;
  z-index: 5;
  position: absolute;
  right: -60%;
  top: 0;
  animation: anilr 12s infinite;
}

.lightadd {
  color: $lightgrey;
  @include font-size($fontsize-M);
}
@keyframes anilr {
  0%,
  100% {
    right: -60%;
  }

  50% {
    right: -40%;
  }
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
  h1,
  h2,
  h3 {
    hyphens: auto;
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
  h1,
  h2 {
    @include font-size($fontsize-XL70);
    line-height: 7rem;
    margin: 0 0 20px;
  }

  h1 + h2,
  h2 + h3 {
    @include font-size($fontsize-L2);
    line-height: 35px;
  }
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {
  h1,
  h2 {
    @include font-size($fontsize-XL60);
    line-height: 6rem;
    margin: 0 0 20px;
  }

  .bildtext h2 {
    @include font-size($fontsize-XL);
    line-height: 4.5rem;
    margin: 0 0 20px;
    word-break: break-word;
  }

  h1 + h2,
  h2 + h3 {
    @include font-size($fontsize-L);
    line-height: 24px;
  }
}
@media all and (max-width:$size-S) {
  h1,
  h2 {
    @include font-size($fontsize-XL);
    line-height: 4.5rem;
  }

  h1 + h2,
  h2 + h3 {
    @include font-size($fontsize-L);
    line-height: 24px;
  }
}
@media all and (max-width:$size-XS) {}
